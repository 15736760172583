export const ErrorFromatterMixin = {
  methods: {
    errorMsgFromResponse (response) {
      let msg = ''
      if (response.data.errors) {
        const keys = Object.keys(response.data.errors)
        keys.forEach((key, index) => {
            msg += ' - ' + response.data.errors[key]
        });
      } else {
        msg = response.data.message
      }
      return msg
    }
  }
}
