<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Edit Client
        </h4>
        <p-button type="info" size="sm" @click.prevent="goClients()">Back</p-button>

        <fg-input v-model="clientAccount.business_name" label="Business Name"></fg-input>

        <p-button type="info" @click.prevent="updateClient()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ClientAccountService from 'src/services/clientAccount.service'

  export default {
    components: {
      Card
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: false,
        clientAccount: {
          id: '',
          business_name: ''
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        if (this.$route.params.id) {
          this.loadClientAccount(this.$route.params.id)
        } else {
          let msg = "id param does not exist so no client to load"
          this.showNotify(msg, 'danger', 'Failed')
          this.goClients()
        }
      },
      goClients () {
        this.$router.replace({ name: 'Clients' })
      },
      async loadClientAccount (id) {
        try {
          this.isLoading = true
          let response = await ClientAccountService.get(id)
          this.clientAccount = response.data
          this.isLoading = false
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
          this.goClients()
        }
      },
      async updateClient () {
        try {
          this.loading = true
          await ClientAccountService.update(this.clientAccount.id, this.clientAccount)
          this.showNotify('Client updated', 'success', 'Success')
          this.loading = false
          this.goClients()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
