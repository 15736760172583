<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Clients</h5>
        <p-button type="info" size="sm" @click.prevent="goAddClient()">Add Client</p-button>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="clientAccounts" header-row-class-name="text-primary">
            <el-table-column prop="business_name"
                             label="Business Name">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import Swal from 'sweetalert2';
  import {Table, TableColumn} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ClientAccountService from 'src/services/clientAccount.service'
  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      PSwitch
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        clientAccounts: []
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        this.loadClientAccounts()
      },
      async loadClientAccounts () {
        let response = await ClientAccountService.index()
        this.clientAccounts = response.data
        this.isLoading = false
        this.isLoaded = true
      },
      goAddClient () {
        this.$router.replace({ name: 'Add Client' })
      },
      handleLike (index, row) {
        alert(`Your clicked on Like button`)
      },
      handleEdit (index, row) {
        this.$router.push({ name: 'Edit Client', params: { id: row.id } })
      },
      handleDelete (index, row) {
        const swalWithBootstrapButtons4 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons4
          .fire({
            title: `Are you sure?`,
            text: `${row.business_name} will be permanently deleted!`,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              this.deleteClient(row)
            }
          });
      },
      async deleteClient (clientAccount) {
        try {
          this.isLoading = true
          await ClientAccountService.destroy(clientAccount.id)
          this.showNotify('Client deleted', 'success', 'Success')
          this.isLoading = false
          this.init()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      },
      getSummaries (param) {
        const { columns } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total'
          } else if (index < columns.length - 1) {
            sums[index] = ''
          } else {
            let sum = 0
            this.productsTable.forEach((obj) => {
              sum += obj.quantity * obj.price
            })
            sums[index] = `€ ${sum}`
          }
        })
        return sums
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
