<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add Client
        </h4>
        <p-button type="info" size="sm" @click.prevent="goClients()">Back</p-button>

        <fg-input v-model="clientAccount.business_name" label="Business Name"></fg-input>

        <p-button type="info" @click.prevent="addClient()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ClientAccountService from 'src/services/clientAccount.service'

  export default {
    components: {
      Card
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: false,
        clientAccount: {
          business_name: ''
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      goClients () {
        this.$router.replace({ name: 'Clients' })
      },
      async addClient () {
        try {
          this.loading = true
          await ClientAccountService.create(this.clientAccount)
          this.showNotify('Client created', 'success', 'Success')
          this.loading = false
          this.goClients()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
